import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from 'react-bootstrap'
import BookingsCalendar from '../components/BookingsCalendar'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

const Page = ({data}) => {

  const {text_intro} = data.mdx.frontmatter

  return (
    <Layout>
      <SEO title="Réservations" />
      
      <Container className="py-4 text-justify">
          <ReactMarkdown parserOptions={{ commonmark: true}} source={text_intro} />

          <BookingsCalendar />

      </Container>
    </Layout>
  )
}  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
              text_intro
            }
        }
    }`