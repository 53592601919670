import React from 'react'
import { Alert, Form, Button } from 'react-bootstrap'
import axios from "axios"
import { navigate, Link } from 'gatsby'
import ChildAgeSelection from './ChildAgeSelection'
import { loadStripe } from '@stripe/stripe-js';

class BookingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form_validated: false,
            email: '',
            name: '',
            phone: '',
            age_child: '',
            dirty: false,
            stripe: null,
            datetime: props.datetime,
            isCard: false,
            isAccessCard: false,
            accessCard: '',
            name_child: '',
            cardUnlimited: false,
            error_msg: '',
            cgv: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.loadStripeLib = this.loadStripeLib.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            dirty: true,
            [name]: value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        console.log(this.state)
        if (this.state.datetime.date === "2021-05-25"){
            alert("La réservation est impossible pour ce créneau exceptionnellement, veuillez sélectionner un autre créneau!")
            return;
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({form_validated: true})
            return
        }
      
        this.setState({form_validated: true, dirty: false, error_msg: ''});
        
        axios({method: 'post', url:'/.netlify/functions/booking', data: this.state}).then(response => {
            if (response.data.session) {
                this.state.stripe.redirectToCheckout({
                    sessionId: response.data.session.id
                }).then(response => {
                    console.log("super")
                }).catch(error => {
                    console.log("error")
                });
            } else {
                this.props.close_modal()
                navigate('/reservation_succes')
            }
        }).catch((err) => {
            console.log(err.response)
            if (err.response) {
                if (err.response.status === 500) {
                    alert("Une erreur technique est survenue, veuillez contacter notre équipe afin qu'on puisse résoudre le problème dans les meilleurs délais")
                } else if (err.response.status === 400) {
                    this.setState({error_msg: err.response.data.msg})
                }
            }
        })
    }

    async loadStripeLib() {
        try {
          const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
          this.setState({ stripe });
        } catch {
          // do nothing
        }
    }

    componentDidMount() {
       this.loadStripeLib()
    }

    renderFormChild() {
        return (
        <>
            <Form.Group className="my-2 mx-2">
                <Form.Label>Prénom de l'enfant</Form.Label>
                <Form.Control
                name="name_child" onChange={this.handleChange} 
                value={this.state.name_child}
                required
                />
            </Form.Group>
            <ChildAgeSelection className="my-2 mx-2" handleSetAge={(age) => this.setState({age_child: age})}/>
        </>)
    }

    renderForm() {
        if (! this.state.isAccessCard && ! this.state.isCard) {
            return (
            <>
                <Button className="btn btn-blue d-block mx-auto my-4" onClick={() => {this.setState({isAccessCard: true})}}>
                    Je souhaite réserver avec ma carte d'accès
                </Button>

                <Button className="btn btn-blue d-block mx-auto my-4" 
                    onClick={() => {this.setState({card: '', isCard: true})}}>
                        Je souhaite réserver en payant par carte bancaire
                </Button>
            </>)
        }

        return <>
            {
                this.state.isAccessCard ? 
                <>
                    <Form.Group className="my-2 mx-2">
                        <Form.Label>Votre numéro de carte d'accès</Form.Label>
                        <Form.Control
                        name="accessCard" onChange={this.handleChange} 
                        value={this.state.accessCard}
                        pattern="[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}"
                        required
                        />
                    </Form.Group>
                
                    <Form.Check 
                        className="my-2 mx-2"
                        type="switch"
                        id="switch-unlimited"
                        name="cardUnlimited"
                        checked={this.state.cardUnlimited}
                        value={this.state.cardUnlimited}
                        onChange={this.handleChange}
                        label="Carte d'accès illimité ?"
                    />
                    {
                        this.state.cardUnlimited ? '' : this.renderFormChild()
                    }
                </>
                : 
                <>

                    <div></div>
                    
                    {
                        this.renderFormChild()
                    }

                    <Form.Group className="my-2 mx-2">
                        <Form.Label>Votre nom et prénom</Form.Label>
                        <Form.Control
                        name="name" onChange={this.handleChange} 
                        value={this.state.name}
                        required
                        />
                    </Form.Group>
            
                    <Form.Group className="my-2 mx-2">
                        <Form.Label>Votre Email</Form.Label>
                        <Form.Control
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                            type="email"
                            pattern=".+@.+\..+"
                            required
                        />
                    </Form.Group>

                    <Form.Group className="my-2 mx-2">
                        <Form.Label>Numéro de téléphone</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Numéro de téléphone"
                            pattern="([0-9]{2}[. -]?){5}"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                </>
            }
            <Form.Group className="my-2 mx-2">
                <Form.Check>
                <Form.Check.Input type="checkbox" name="cgv"
                        value={this.state.cgv}
                        onChange={this.handleChange} />
                <Form.Check.Label>
                    En cochant cette case, je déclare avoir pris connaissance des <Link to='/cgv'>conditions générales de vente</Link>
                </Form.Check.Label>
            
            </Form.Check>
            </Form.Group>

        <Button 
            disabled={! this.state.dirty || ! this.state.cgv}
            type="submit"
            className="btn btn-green d-block mx-auto my-4"
        >
            Réserver la demi-journée
        </Button>
        </>
    }

    render() {
        return (
                <Form
                    validated={this.state.form_validated} 
                    onSubmit={this.handleSubmit}
                    className="border rounded px-4 needs-validation" noValidate>
                    {
                        this.renderForm()
                    }   
                    <Alert variant="danger" className={this.state.error_msg ? '': 'd-none'}>{this.state.error_msg}</Alert>
                    
                </Form>
        )
    }
}

export default BookingForm
