import React from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { Modal } from 'react-bootstrap'
import moment from 'moment-timezone'
import moment_base from 'moment'
import frLocal from 'moment/locale/fr'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import BookingForm from '../components/BookingForm'
import axios from 'axios'

moment_base.locale('fr', frLocal);
moment.defineLocale('fr', moment_base.localeData()._config)
moment.locale('fr')

const propTypes = {}

class Selectable extends React.Component {
  constructor(...args) {
    super(...args)

    this.handleEventPropGetter = this.handleEventPropGetter.bind(this)
    this.handleSelectEvent = this.handleSelectEvent.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.state = {
      loading: true,
      showModal: false,
      datetime: {date: moment()},
      bookings: {},
      events: [],
      bookingConfiguration: {
        ranges: {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
        }
      }
    }
  }

  loadBookings() {
    axios({method: 'get', url:'/.netlify/functions/bookingConfiguration'}).then((response) => {
      this.setState({
        bookingConfiguration: response.data.booking_configuration
      }, () => {
        axios({method: 'post', url:'/.netlify/functions/bookingsGroup'}).then((response) => {
          this.setState({
            bookings: response.data.bookings
          }, () => this.createEvents())
        })
      })
    })
  }

  createEvents() {
    let currentDate = moment()
    const gameOver = new Date('2022-06-13')
    let events = []
    for (let nDay=0; nDay < 90; nDay++) {
      const ranges = this.state.bookingConfiguration.ranges[currentDate.day()]
      for (let i=0; i < ranges.length; i++) {
        let range = ranges[i]
        const startDate = moment.tz(currentDate.format('YYYY-MM-DDT') + range.start, 'Europe/Paris')
        if (startDate > gameOver) {
          break
        }
        const endDate = moment.tz(currentDate.format('YYYY-MM-DDT') + range.end, 'Europe/Paris')
        if (moment() < endDate){ 
          const datetime = {
            date: currentDate.format('YYYY-MM-DD'),
            start: range.start,
            end: range.end
          }
          let event = {
            title: 'Réserver',
            start: startDate.toDate(),
            end: endDate.toDate(),
            full: false,
            datetime: datetime
          }

          const rangeKey = datetime.start + datetime.end
          const specificConfig = this.state.bookingConfiguration?.specificConfig?.[datetime.date]?.[rangeKey]
          if (specificConfig && specificConfig['closed']) {
            continue
          }

          if (specificConfig && specificConfig['full']) {
            event['full'] = true
            event['title'] = "Complet"
          }

          let maxChildren = this.state.bookingConfiguration.nbChildren

          if (specificConfig && specificConfig['nbChildren'] && specificConfig['nbChildren'] > 0) {
              maxChildren = specificConfig['nbChildren']
          }

          if (! maxChildren) {
            maxChildren = 10
          }


          const bookedEvent = this.state.bookings[datetime.date]
          if (bookedEvent) {
            const nbBookings = bookedEvent[datetime.start + '-' + datetime.end]

            if (nbBookings >= maxChildren) {
              event['full'] = true
              event['title'] = "Complet"
            }
          }
          events.push(event)
        }
      }
      currentDate = currentDate.add(1, 'days')
    }
    this.setState({events: events, loading: false})
  }


  componentDidMount() {
    this.loadBookings()
  }

  handleSelectEvent(event) {
    if (! event.full) {
      this.setState({showModal: true, datetime: event.datetime})
    }
  }

  handleEventPropGetter(event, start, end, isSelected) {

    let style = {
      backgroundColor: '#4D9791',
      borderRadius: 0
    }

    if (event.full) {
      style['backgroundColor'] = 'red'
    }

    return {
      style: style
    }
  }

 
  closeModal() {
    this.setState({showModal: false})
  }

  renderModal() {
    return(
      <Modal show={this.state.showModal} onHide={this.closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Réservation pour le {moment(this.state.datetime.date).format('LL')} de {moment.tz(this.state.datetime.date + 'T'+ this.state.datetime.start, 'Europe/Paris').format("HH[h]mm")} à {moment.tz(this.state.datetime.date + 'T'+ this.state.datetime.end, 'Europe/Paris').format("HH[h]mm")}
          </Modal.Title>
        </Modal.Header>
        <BookingForm datetime={this.state.datetime} close_modal={this.closeModal} />
      </Modal>
    )

  }

  render() {
    const localizer = momentLocalizer(moment)

    let formats = {
      dateFormat: 'dd',
      dayFormat: 'ddd DD MMM',
      dayRangeHeaderFormat: ({ start, end }, culture, localizer) => 
      localizer.format(start, 'DD MMM' , culture) + ' — ' + localizer.format(end, 'DD MMM' , culture),
    }
    return (
      <>
        {
          this.state.showModal ? this.renderModal() : ''
        }
        <div className={this.state.loading ? 'loadingCalendar' : null}>
        <Calendar
            localizer={localizer}
            events={this.state.events}
            defaultView={Views.WEEK}
            views={[Views.WEEK]}
            culture='fr'
            style={{
                height: "700px"
            }}
            formats={formats}
            min={new Date(0, 0, 0, 10, 0, 0)}
            max={new Date(0, 0, 0, 18, 30, 0)}
            scrollToTime={new Date()}
            eventPropGetter={this.handleEventPropGetter}
            onSelectEvent={this.handleSelectEvent}
            messages={{ 
              date: 'Date',
              time: 'Time',
              event: 'Event',
              allDay: 'Toute le journée',
              week: 'Semaine',
              work_week: 'Semaine ouvrée',
              day: 'Jour',
              month: 'Mois',
              previous: 'Précédent',
              next: 'Suivant',
              yesterday: 'Hier',
              tomorrow: 'Demain',
              today: "Aujourd'hui",
              agenda: 'Agenda',
              noEventsInRange: 'There are no events in this range.',
              showMore: function(e) {
              return '+' + e + ' more'
          }}}
        />
        </div>
      </>
    )
  }
}

Selectable.propTypes = propTypes

export default Selectable
